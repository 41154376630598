// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-blog-src-templates-article-template-js": () => import("./../node_modules/gatsby-theme-blog/src/templates/article-template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-article-template-js" */),
  "component---node-modules-gatsby-theme-blog-src-templates-list-template-js": () => import("./../node_modules/gatsby-theme-blog/src/templates/list-template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-list-template-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

